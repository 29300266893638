import React, { useMemo } from 'react';
import { Analytics } from '@vercel/analytics/react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider, CssBaseline, GlobalStyles } from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import theme from "./theme"; // Import the theme
import Layout from "./components/layout/Layout"; // Import the new Layout component
import AppRoutes from "./AppRoutes";
import { AuthProvider } from "./components/account/AuthContext"; // Ensure correct path
import { MembershipProvider } from "./components/account/MembershipContext";
import { LocationProvider } from "./contexts/LocationContext";
import { useStripeConfig } from "./hooks/useStripeConfig";
import "./fonts.css";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        <GlobalStyles
          styles={{
            '#root': {
              backgroundColor: theme.palette.black,
            },
          }}
        />
        <LocationProvider>
          <StripeProvider>
            <AuthProvider>
              <MembershipProvider>
                <Router>
                  <Layout>
                    <AppRoutes />
                  </Layout>
                  <ToastContainer />
                </Router>
              </MembershipProvider>
            </AuthProvider>
          </StripeProvider>
        </LocationProvider>
        <Analytics />
      </LocalizationProvider>
    </ThemeProvider>
  );
}

function StripeProvider({ children }) {
  const { publishableKey } = useStripeConfig();
  const stripePromise = useMemo(() => {
    if (process.env.NODE_ENV === 'development') {
      console.log('Initializing Stripe instance');
    }
    return loadStripe(publishableKey);
  }, [publishableKey]);

  return (
    <Elements stripe={stripePromise}>
      {children}
    </Elements>
  );
}

export default App;
