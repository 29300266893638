import React, { useState, useContext } from "react";
import { Box, useTheme, Typography, IconButton, Link, useMediaQuery } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import InstagramIcon from "@mui/icons-material/Instagram";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import { LocationContext } from "../../contexts/LocationContext";

const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { location } = useContext(LocationContext);

  const handleOpenModal = (content) => {
    const pdfPath = content === "terms" ? "/Terms_Condition.pdf" : "/Privacy_Policy.pdf";
    window.open(pdfPath, '_blank');
  };

  return (
    <Box
      sx={{
        bgcolor: theme.palette.black,
        py: 1,
        pt: 4,
        width: '100%',
        height: 'auto',
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        px: isMobile ? 2 : 8,
        gap: isMobile ? 1 : 0,
      }}
    >
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: 2,
      }}>
        <Link
          component="button"
          variant="p4"
          onClick={() => handleOpenModal("terms")}
          sx={{ 
            color: theme.palette.white,
            fontSize: isMobile ? '0.9rem' : '1.2rem',
            cursor: 'pointer',
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
        >
          Terms
        </Link>
        
        <Link
          component="button"
          variant="p4"
          onClick={() => handleOpenModal("privacy")}
          sx={{ 
            color: theme.palette.white,
            fontSize: isMobile ? '0.9rem' : '1.2rem',
            cursor: 'pointer',
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
        >
          Privacy
        </Link>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, justifyContent: 'flex-end' }}>
        <IconButton
          size="small"
          sx={{ color: theme.palette.white }}
          href="https://www.instagram.com/saunasocial/?hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          <InstagramIcon fontSize="small" />
        </IconButton>
        <IconButton
          size="small"
          sx={{ color: theme.palette.white }}
          href="mailto:saunasocial@gmail.com"
        >
          <EmailOutlinedIcon fontSize="small" />
        </IconButton>
        <Link
          component={RouterLink}
          to="/select-location"
          sx={{
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.white,
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline',
            },
            fontSize: isMobile ? '0.7rem' : '1rem',
          }}
        >
          <PlaceOutlinedIcon sx={{ mr: 0.5, fontSize: isMobile ? '0.9rem' : '1.2rem' }} />
          <Typography variant="p4" sx={{ fontSize: isMobile ? '0.9rem' : '1.2rem' }}>
            {location || "Select Location"}
          </Typography>
        </Link>
      </Box>
    </Box>
  );
};

export default Footer;
