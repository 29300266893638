import React, { createContext, useState, useEffect } from 'react';

export const LocationContext = createContext();

export const LocationProvider = ({ children }) => {
  const [location, setLocation] = useState(() => {
    return localStorage.getItem('selectedLocation') || null;
  });

  useEffect(() => {
    if (location) {
      localStorage.setItem('selectedLocation', location);
    } else {
      localStorage.removeItem('selectedLocation');
    }
  }, [location]);

  return (
    <LocationContext.Provider value={{ location, setLocation }}>
      {children}
    </LocationContext.Provider>
  );
};