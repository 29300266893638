import { useContext, useMemo } from 'react';
import { LocationContext } from '../contexts/LocationContext';

const stripeConfigs = {
  'Los Angeles, CA': {
    publishableKey: process.env.REACT_APP_LA_STRIPE_PUBLISHABLE_KEY,
  },
  'Indianapolis, IN': {
    publishableKey: process.env.REACT_APP_INDY_STRIPE_PUBLISHABLE_KEY,
  }
};

export function useStripeConfig() {
  const { location } = useContext(LocationContext);
  
  return useMemo(() => {
    const config = stripeConfigs[location] || stripeConfigs['Los Angeles, CA'];

    if (!config) {
      throw new Error(`No Stripe configuration found for location: ${location}`);
    }

    console.log(`Using Stripe config for location ${location}`);
    
    return config;
  }, [location]);
}
