import { useContext } from 'react';
import { LocationContext } from '../contexts/LocationContext';

const apiConfigs = {
  'Los Angeles, CA': {
    apiUrl: process.env.REACT_APP_ENV === 'local' 
      ? 'http://127.0.0.1:5000'
      : process.env.REACT_APP_LA_API_URL,
  },
  'Indianapolis, IN': {
    apiUrl: process.env.REACT_APP_ENV === 'local'
      ? 'http://127.0.0.1:5000'
      : process.env.REACT_APP_INDY_API_URL,
  }
};

export function useApiConfig() {
  const { location } = useContext(LocationContext);
  const config = apiConfigs[location] || apiConfigs['Los Angeles, CA'];

  if (!config) {
    throw new Error(`No API configuration found for location: ${location}`);
  }

  return config;
}
