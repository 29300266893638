import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { useContext, useMemo, useEffect, useState } from 'react';
import { LocationContext } from './contexts/LocationContext';

const firebaseConfigs = {
  'Los Angeles, CA': {
    apiKey: process.env.REACT_APP_LA_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_LA_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_LA_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_LA_FIREBASE_STORAGE_BUCKET,
    clientId: process.env.REACT_APP_LA_FIREBASE_GOOGLE_CLIENT_ID,
  },
  'Indianapolis, IN': {
    apiKey: process.env.REACT_APP_INDY_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_INDY_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_INDY_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_INDY_FIREBASE_STORAGE_BUCKET,
    clientId: process.env.REACT_APP_INDY_FIREBASE_GOOGLE_CLIENT_ID,
  }
};

// Create a singleton instance map for each location
let firebaseInstances = {};

// Initialize Firebase for a specific location
const initializeFirebase = (config, location) => {
  // Check if we already have an instance for this location
  if (firebaseInstances[location]) {
    // console.log(`Returning existing Firebase instance for ${location}`);
    return firebaseInstances[location];
  }

  console.log(`Initializing new Firebase instance for ${location} with config:`, {
    ...config,
    apiKey: config.apiKey ? '[REDACTED]' : undefined
  });

  const app = initializeApp(config, location); // Use location as app name
  const auth = getAuth(app);
  const db = getFirestore(app);
  const storage = getStorage(app);
  
  console.log('Firebase storage initialized for location:', {
    location,
    storageBucket: storage.app.options.storageBucket,
    appName: storage.app.name
  });

  const googleProvider = new GoogleAuthProvider();

  firebaseInstances[location] = { app, auth, db, storage, googleProvider };
  return firebaseInstances[location];
};

export function useFirebase() {
  const { location } = useContext(LocationContext);
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  
  const firebase = useMemo(() => {
    const config = firebaseConfigs[location] || firebaseConfigs['Los Angeles, CA'];
    
    if (!config) {
      throw new Error(`No Firebase configuration found for location: ${location}`);
    }

    if (!config.apiKey) {
      throw new Error(`Firebase API key is missing for location: ${location}`);
    }

    const instance = initializeFirebase(config, location);
    return { ...instance, isOnline };
  }, [location, isOnline]); // Dependencies include location

  // Monitor online/offline status
  useEffect(() => {
    const handleOnline = () => {
      console.log('App is online');
      setIsOnline(true);
    };

    const handleOffline = () => {
      console.log('App is offline');
      setIsOnline(false);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // Check connection status immediately
    setIsOnline(window.navigator.onLine);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  // Add retry logic for failed operations
  const withRetry = async (operation, maxRetries = 3) => {
    for (let i = 0; i < maxRetries; i++) {
      try {
        return await operation();
      } catch (error) {
        if (error.code === 'failed-precondition' || error.message.includes('offline')) {
          console.log(`Retry attempt ${i + 1} of ${maxRetries}`);
          await new Promise(resolve => setTimeout(resolve, 1000 * (i + 1))); // Exponential backoff
          continue;
        }
        throw error;
      }
    }
    throw new Error('Operation failed after max retries');
  };

  return {
    ...firebase,
    withRetry,
    isOnline
  };
}
