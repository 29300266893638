import React, { Suspense, lazy, useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { LocationContext } from "./contexts/LocationContext";

// Lazy load components
const Home = lazy(() => import("./components/home/Home"));
const BookSession = lazy(() => import("./components/booking/BookSessionPage"));
const Gallery = lazy(() => import("./components/gallery/Gallery"));
const ConfirmationPage = lazy(() => import("./components/booking/ConfirmationPage"));
const MembershipPayment = lazy(() => import("./components/account/MembershipPayment"));
const UserAccount = lazy(() => import("./components/account/UserAccount"));
const FindUs = lazy(() => import("./components/location/FindUs"));
const NotFound = lazy(() => import("./components/NotFound"));
const ContactUs = lazy(() => import("./components/contact/ContactUs"));
const FaqPage = lazy(() => import("./components/faq/FaqPage"));
const LocationSelectionScreen = lazy(() => import("./components/location/LocationSelectionScreen"));
const AdminPortal = lazy(() => import("./components/admin/AdminPortal"));
const Events = lazy(() => import("./components/events/Events"));
const GiftCardPurchase = lazy(() => import("./components/giftcard/GiftCardPurchase"));

const AppRoutes = () => {
  const { location, setLocation } = useContext(LocationContext);

  const handleLocationSelect = (newLocation) => {
    setLocation(newLocation);
  };

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/select-location" element={<LocationSelectionScreen onLocationSelect={handleLocationSelect} />} />
        {!location ? (
          <Route path="*" element={<Navigate to="/select-location" replace />} />
        ) : (
          <>
            <Route path="/" element={<Home />} />
            <Route path="/book-a-session" element={<BookSession key={Date.now()} />} />
            <Route path="/find-us" element={<FindUs />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/faq" element={<FaqPage />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/account" element={<UserAccount />} />
            <Route path="/become-a-member" element={<MembershipPayment />} />
            <Route path="/confirmation" element={<ConfirmationPage />} />
            <Route path="/admin" element={<AdminPortal />} />
            <Route path="/events" element={<Events />} />
            <Route path="/gift-card" element={<GiftCardPurchase />} />
            <Route path="*" element={<NotFound />} />
          </>
        )}
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
