// context/MembershipContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import { useApiConfig } from '../../hooks/useApiConfig';
import { toast } from 'react-toastify';

const MembershipContext = createContext();

export const useMembership = () => {
  const context = useContext(MembershipContext);
  if (!context) {
    throw new Error('useMembership must be used within a MembershipProvider');
  }
  return context;
};

export const MembershipProvider = ({ children }) => {
  const [membershipPrice, setMembershipPrice] = useState(null);
  const [priceId, setPriceId] = useState(null);
  const { apiUrl } = useApiConfig();

  useEffect(() => {
    const fetchMembershipPrice = async () => {
      try {
        const productsResponse = await fetch(`${apiUrl}/api/products`);
        const productsData = await productsResponse.json();
        
        const membershipProduct = productsData.data.find(
          product => product.name === 'Membership'
        );

        if (!membershipProduct) {
          console.error('Membership product not found');
          return;
        }

        const defaultPrice = membershipProduct.default_price;
        if (defaultPrice) {
          setMembershipPrice(defaultPrice.unit_amount / 100);
          setPriceId(defaultPrice.id);
        }
      } catch (error) {
        console.error('Error fetching membership price:', error);
        toast.error('Error loading membership details');
      }
    };

    fetchMembershipPrice();
  }, [apiUrl]);

  return (
    <MembershipContext.Provider value={{ membershipPrice, priceId }}>
      {children}
    </MembershipContext.Provider>
  );
};
