import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  Button,
  List,
  ListItem,
  ListItemText,
  useTheme,
  Divider,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { AuthContext } from "../account/AuthContext";
import { doc, getDoc } from "firebase/firestore";
import { LocationContext } from "../../contexts/LocationContext";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import { useFirebase } from '../../firebaseConfig';

const pages = [
  "Book a Session",
  "Find Us",
  "FAQ",
  "Events",
  "Gallery",
  "Account",
  "Become a Member",
  "Gift Card",
  "Contact Us",
];

const leftPages = ["Book a Session", "Find Us", "Gallery", "Events"];
const rightPages = ["FAQ", "Gift Card", "Contact Us", "Account"];

const useResponsiveDrawer = () => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const [showDrawer, setShowDrawer] = useState(!isMdUp);
  const headerRef = useRef(null);

  useEffect(() => {
    const checkOverflow = () => {
      if (headerRef.current) {
        const headerWidth = headerRef.current.offsetWidth;
        const logoWidth = headerRef.current.querySelector('.logo-container')?.offsetWidth || 0;
        const buttonsWidth = Array.from(headerRef.current.querySelectorAll('.nav-button'))
          .reduce((total, button) => total + button.offsetWidth, 0);
        
        setShowDrawer(logoWidth + buttonsWidth > headerWidth || !isMdUp);
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, [isMdUp]);

  return { showDrawer, headerRef };
};

const HeaderWithDrawer = () => {
  const { db } = useFirebase();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [logoUrl, setLogoUrl] = useState("");
  const [userDetails, setUserDetails] = useState(null);
  const { auth } = useContext(AuthContext);
  const { location, setLocation } = useContext(LocationContext);
  const navigate = useNavigate();
  const currentPath = useLocation();
  const theme = useTheme();
  const { showDrawer, headerRef } = useResponsiveDrawer();

  const isTransparentBackground = currentPath.pathname === "/" || currentPath.pathname === "/book-a-session";

  useEffect(() => {
    if (auth?.uid) {
      const fetchUserDetails = async () => {
        const userDoc = await getDoc(doc(db, "users", auth.uid));
        if (userDoc.exists()) {
          setUserDetails(userDoc.data());
        } else {
          setUserDetails(null);
        }
      };
      fetchUserDetails();
    } else {
      setUserDetails(null);
    }
  }, [auth]);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleDrawerItemClick = (page) => {
    setDrawerOpen(false);
    if (page === "Book a Session" && currentPath.pathname === "/book-a-session") {
      navigate("/book-a-session", { replace: true });
    } else {
      navigate(`/${page.toLowerCase().replace(/\s+/g, "-")}`);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (isTransparentBackground) {
        setScrollPosition(window.scrollY);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isTransparentBackground]);

  useEffect(() => {
    const fetchLogoUrl = async () => {
      setLogoUrl("/bus-logo.png");
    };

    fetchLogoUrl();
  }, []);

  const handleLogoClick = () => {
    navigate("/");
    window.scrollTo(0, 0);
  };

  const getInitials = (firstName, lastName) => {
    if (!firstName || !lastName) return "";
    return `${firstName[0]}${lastName[0]}`;
  };

  const scrolledDown = isTransparentBackground ? scrollPosition > 0 : true;

  const textColor = scrolledDown
    ? theme.palette.white
    : isTransparentBackground
    ? theme.palette.white
    : theme.palette.white;

  const headerTextColor = scrolledDown
    ? theme.palette.orange.dark
    : isTransparentBackground
    ? theme.palette.orange.dark
    : theme.palette.orange.dark;

  const gradientStyle = {
    background: `linear-gradient(180deg, ${theme.palette.green.dark} 30%, ${theme.palette.green.darker} 90%)`,
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    fontSize: "16px",
    fontWeight: "bold",
    cursor: "pointer",
    ml: theme.spacing(2),
  };

  const drawer = (
    <Box
      sx={{
        width: 250,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        "& .MuiDrawer-paper": {
          width: "75%",
          boxSizing: "border-box",
          color: theme.palette.black,
          paddingTop: "64px",
        },
      }}
      role="presentation"
    >
      <Box>
        <IconButton
          onClick={handleDrawerToggle}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.black,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Divider />
        <List
          disablePadding
          sx={{ paddingTop: theme.spacing(6), paddingLeft: theme.spacing(2) }}
        >
          {pages.map((text) => (
            <ListItem
              button
              key={text}
              onClick={() => handleDrawerItemClick(text)}
              sx={{
                justifyContent: "center",
                "& .MuiListItemText-primary": {
                  fontSize: "1rem",
                  fontWeight: "600",
                  fontFamily: "Work Sans",
                },
                "&:hover": {
                  backgroundColor: "transparent",
                },
                my: 1,
              }}
            >
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
      </Box>
      <Box
        sx={{
          padding: theme.spacing(2),
          borderTop: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Button
          startIcon={<PlaceOutlinedIcon />}
          onClick={() => {
            handleDrawerToggle();
            navigate("/select-location");
          }}
          sx={{
            color: theme.palette.black,
            justifyContent: "flex-start",
            width: "100%",
            textTransform: "none",
            fontFamily: "Work Sans",
            fontWeight: 600,
            fontSize: ".8rem",
          }}
        >
          {location || "Select Location"}
        </Button>
      </Box>
    </Box>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: "none",
          height: "94px",
          backgroundColor: isTransparentBackground
            ? scrollPosition > 0
              ? theme.palette.black
              : "transparent"
            : theme.palette.black,
          transition: "background-color 0.3s ease-in-out",
        }}
      >
        <Toolbar
          ref={headerRef}
          disableGutters
          sx={{
            height: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            position: "relative",
          }}
        >
          {!showDrawer && (
            <Box
              sx={{
                display: { xs: 'none', md: 'flex' },
                alignItems: "center",
                height: "100%",
                justifyContent: "flex-start",
                flexGrow: 0,
                marginLeft: theme.spacing(1),
              }}
            >
              {leftPages.map((page) => (
                <Button
                  key={page}
                  className="nav-button"
                  onClick={() => handleDrawerItemClick(page)}
                  sx={{
                    color: textColor,
                    fontFamily: "Work Sans",
                    fontWeight: 600,
                    textTransform: "none",
                    fontSize: "20px",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {page}
                </Button>
              ))}
            </Box>
          )}

          <Box
            className="logo-container"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
              height: "100%",
              cursor: "pointer",
            }}
            onClick={handleLogoClick}
          >
            <Typography
              variant="h1"
              component="div"
              sx={{
                fontFamily: "Bobby Jones, sans-serif",
                color: headerTextColor,
                marginRight: theme.spacing(1),
                fontSize: { xs: theme.typography.h2.fontSize, sm: theme.typography.h1.fontSize, md: theme.typography.h1.fontSize },
              }}
            >
              SAUNA
            </Typography>
            {logoUrl && (
              <Box
                component="img"
                sx={{
                  height: "auto",
                  width: "100px",
                }}
                src={logoUrl}
                alt="Sauna Social Logo"
              />
            )}
            <Typography
              variant="h1"
              component="div"
              sx={{
                fontFamily: "Bobby Jones, sans-serif",
                color: headerTextColor,
                marginLeft: theme.spacing(1),
                fontSize: { xs: theme.typography.h2.fontSize, sm: theme.typography.h1.fontSize, md: theme.typography.h1.fontSize },
              }}
            >
              SOCIAL
            </Typography>
          </Box>

          {!showDrawer && (
            <Box
              sx={{
                display: { xs: 'none', md: 'flex' },
                alignItems: "center",
                height: "100%",
                justifyContent: "flex-end",
                flexGrow: 0,
                marginRight: theme.spacing(1),
              }}
            >
              {rightPages.map((page) => (
                <Button
                  key={page}
                  className="nav-button"
                  onClick={() => handleDrawerItemClick(page)}
                  sx={{
                    color: textColor,
                    fontFamily: "Work Sans",
                    fontWeight: 600,
                    textTransform: "none",
                    fontSize: "20px",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {page}
                </Button>
              ))}
            </Box>
          )}

          {showDrawer && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                width: "100%",
                height: "100%",
              }}
            >
              <IconButton
                size="large"
                edge="end"
                aria-label="menu"
                aria-haspopup="true"
                onClick={handleDrawerToggle}
                sx={{
                  color: textColor,
                  position: "absolute",
                  right: { xs: theme.spacing(1), sm: theme.spacing(2), md: theme.spacing(3)},
                }}
              >
                <MenuIcon />
              </IconButton>
            </Box>
          )}
        </Toolbar>
      </AppBar>

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerToggle}
        sx={{
          ".MuiDrawer-paper": {
            width: "75%",
            background: `linear-gradient(45deg, #CDDFDF, #F9CE9B)`,
            color: theme.palette.black,
          },
        }}
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

export default HeaderWithDrawer;
