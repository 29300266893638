import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    white: "#FFFFFF",
    lightGrey: "#F1F1F1",
    khaki: "#EBE2D4",
    orange: { primary: "#FFB660", light: "#EECEA9", dark: "#FE8A02" },
    blue: { primary: "#CDE6E5", dark: "#95C6C3" },
    green: { primary: "#C9D3BB", dark: "#556D5F", darker: "#758C80" },
    gradient: `linear-gradient(to bottom right, #EECEA9, #CDE6E5)`,
    black: "#12222A",
    brown: "#8E460B",
    yellow: "#FFF3CB",
    text: {
      primary: "#12222A",
      secondary: "#758C80",
    },
  },
  fontFamily: { primary: "Bobby Jones, sans-serif", secondary: "Work Sans" },
  divider: "#BDC3C7",
  background: {
    paper: "#F1F1F1",
    default: "#FFF3CB",
  },
  typography: {
    h1: {
      fontFamily: '"Bobby Jones", sans-serif',
      fontSize: "40px",
      fontWeight: "400",
      lineHeight: "52px",
      letterSpacing: "1px",
      textAlign: "center",
    },
    h2: {
      fontFamily: '"Bobby Jones", sans-serif',
      fontSize: "32px",
      fontWeight: "400",
      lineHeight: "41.6px",
      letterSpacing: "1px",
      textAlign: "center",
    },
    h3: {
      fontFamily: '"Bobby Jones", sans-serif',
      fontSize: "24px",
      fontWeight: "400",
      lineHeight: "31.2px",
      letterSpacing: "1px",
      textAlign: "center",
    },
    h4: {
      fontFamily: '"Bobby Jones", sans-serif',
      fontSize: "20px",
      fontWeight: "400",
      lineHeight: "26px",
      letterSpacing: "1px",
      textAlign: "center",
    },
    h5: {
      fontFamily: '"Bobby Jones", sans-serif',
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "20.8px",
      letterSpacing: "1px",
      textAlign: "center",
    },
    h6: {
      fontFamily: '"Bobby Jones", sans-serif',
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "18.2px",
      letterSpacing: "1px",
      textAlign: "center",
    },
    p1: {
      fontFamily: "Work Sans",
      fontSize: "24px",
      fontWeight: "500",
      lineHeight: "31.2px",
      textAlign: "left",
    },
    p2: {
      fontFamily: "Work Sans",
      fontSize: "20px",
      fontWeight: "400",
      lineHeight: "26px",
      textAlign: "center",
    },
    p3: {
      fontFamily: "Work Sans",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "20.8px",
      textAlign: "center",
    },
    p4: {
      fontFamily: "Work Sans",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "18.2px",
      letterSpacing: ".2px",
    },
    p5: {
      fontFamily: "Work Sans",
      fontSize: "12px",
      fontWeight: "400",
      lineHeight: "15.6px",
      textAlign: "center",
    },
    p6: {
      fontFamily: "Work Sans",
      fontSize: "10px",
      fontWeight: "400",
      lineHeight: "13px",
      textAlign: "center",
    },
    button: {
      fontFamily: "Work Sans",
      fontSize: "14px",
      fontWeight: "600",
      lineHeight: "18.2px",
      letterSpacing: "1px",
      textAlign: "center",
    },
  },
  border: "1px solid #12222A",
  shadows: Array(25).fill("none"),
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "20px",
          letterSpacing: "0.1rem",
          minWidth: 0,
          padding: "6px 16px",
          fontFamily: "Work Sans",
          fontWeight: 600,
          textTransform: "uppercase", // This should make all button texts uppercase
        },
        primary: {
          borderRadius: "8px",
          textTransform: "uppercase", // This should make all button texts uppercase
          letterSpacing: "0.1rem",
          minWidth: "200px",
          width: "300px",
          padding: 14,
          fontFamily: "Work Sans",
          fontWeight: "bolder",
          boxShadow: "none",
          backgroundColor: "#FFB660",
          color: "#12222A",
          border: "1px solid #12222A",
          "&:hover": {
            backgroundColor: "#12222A",
            border: "1px solid #FFB660",
            color: "#FFB660",
          },
        },
        secondary: {
          borderRadius: "8px",
          textTransform: "uppercase", // This should make all button texts uppercase
          letterSpacing: "0.1rem",
          minWidth: "200px",
          padding: "8px 32px",
          fontFamily: "Work Sans",
          fontWeight: 600,
          boxShadow: "none",
          backgroundColor: "#CDE6E5",
          color: "#12222A",
          border: "1px solid #12222A",
          "&:hover": {
            backgroundColor: "#12222A",
            color: "#CDE6E5",
          },
        },
        warning: {
          borderRadius: "8px",
          textTransform: "uppercase", // This should make all button texts uppercase
          letterSpacing: "0.1rem",
          minWidth: "200px",
          padding: "8px 32px",
          fontFamily: "Work Sans",
          fontWeight: 600,
          boxShadow: "none",
          backgroundColor: "red",
          color: "#12222A",
          border: "1px solid #12222A",
          "&:hover": {
            backgroundColor: "#12222A",
            color: "#CDE6E5",
          },
        },
        black: {
          backgroundColor: "#12222A",
          color: "#FFFFFF",
          "&:hover": {
            backgroundColor: "#FFFFFF",
            color: "#12222A",
          },
        },
        toggle: {
          backgroundColor: "#12222A",
          color: "#FFFFFF",
          "&:hover": {
            backgroundColor: "#FFFFFF",
            color: "#12222A",
          },
          "&.Mui-selected": {
            backgroundColor: "#12222A",
            color: "#FFFFFF",
          },
          "&.Mui-selected:hover": {
            backgroundColor: "#FFFFFF",
            color: "#12222A",
          },
        },
        outlined: {
          borderRadius: "10px",
          textTransform: "uppercase", // This should make all button texts uppercase
          letterSpacing: "0.1rem",
          minWidth: "200px",
          width: "300px",
          fontFamily: "Work Sans",
          fontWeight: 600,
          boxShadow: "none",
          backgroundColor: "#CDE6E5",
          color: "#12222A",
          border: "1px solid #12222A",
          "&:hover": {
            backgroundColor: "#B0D5D1",
          },
          padding: 14,
        },
        serviceButton: {
          borderRadius: "20px",
          textTransform: "uppercase", // This should make all button texts uppercase
          letterSpacing: "0.1rem",
          minWidth: 0,
          padding: "6px 16px",
          margin: "4px",
          lineHeight: 1.25,
          fontWeight: 500,
          fontSize: "0.875rem",
          boxShadow: "none",
          "&:hover": {
            backgroundColor: "inherit",
          },
        },
        activeServiceButton: {
          fontWeight: 800,
          borderRadius: "20px",
          "&:hover": {
            backgroundColor: "inherit",
            borderColor: "inherit",
          },
        },
      },
    },
  },
});

export default theme;
